<script setup>
import { ref, reactive } from 'vue';
import Layout from '@/Layouts/Layout.vue';
import Heading1 from '@/Components/Heading1.vue';
import Heading2 from '@/Components/Heading2.vue';
import ButtonStd from '@/Components/ButtonStd.vue';
import Text from '@/Components/Text.vue';
import Note from '@/Components/Note.vue';
import { usePage } from '@inertiajs/vue3';
import Search from '@/Components/Search.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import Tile from '@/Pages/Home/Partials/Tile.vue';


const props = defineProps({ 
    config: Array,
});

const layout = ref();

const go = (href) => {
    window.location.href = href;
}

const state = reactive({ 
    text: "",           // Search text
 }); 

/**
 * Redirect to explore view
 */
 const search = () => {
    window.location.href = "/set/explore/" + encodeURIComponent(state.text);
}

</script>

<template>
    <Layout :title="'home'" :search="false" :navBar="usePage().props.auth.user ? true : false" ref="layout" :config="props.config">
        <template #header>
            <Text :text="'home'"/>
        </template>
 
        <div v-if="usePage().props.auth.user" class="flex flex-col gap-4 p-6">

            <Tile :image="'/asset/media/logo/logo-s.svg'">
                <template #content>
                    <Heading1 :text="'home.welcome1'" class="font-dynapuff !mb-2"/>
                    <div class="flex gap-2 justify-center items-center">
                        <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                        <ButtonStd :active="true" @click="go('/report/show')" :label="'activity'"/> 
                    </div>
                    <Note :text="'home.text1'" class="text-center"/> 
                </template>
            </Tile>

            <SectionBorder/>

            <Tile :image="'/asset/media/img/library.jfif'">
                <template #content>
                    <Heading1 :text="'home.explore.title'" class="font-dynapuff !mb-2"/>
                    <div class="w-full flex justify-start items-center">
                        <Search v-model="state.text" :placeholder="'set.explore.search'" :class="'w-full'" @search="search"/>
                    </div>
                    <Note :text="'home.explore.text'" class="text-center"/> 
                </template>
            </Tile>

            <SectionBorder/>

            <Tile :image="'/asset/media/img/classroom.jfif'">
                <template #content>
                    <Heading1 :text="'home.school.title'" class="font-dynapuff !mb-2"/>
                    <div  class="flex gap-2 justify-center items-center">
                        <ButtonStd :active="true" @click="go('/set/library')" :label="'set.library'"/> 
                        <ButtonStd v-if="usePage().props.auth.user.role == 1" :active="true" @click="go('/school/show')" :label="'school'"/> 
                    </div>
                    <Note v-if="usePage().props.auth.user.role != 1" :text="'home.school.not_teacher'"/> 
                    <Note :text="'home.school.text'" class="text-center"/> 
                </template>
            </Tile>
        </div>     
        
        <div v-else class="flex flex-col gap-4 p-6">

            <Tile :image="'/asset/media/logo/logo-s.svg'">
                <template #content>
                    <Heading1 :text="'home.welcome1'" class="!mb-1 font-dynapuff text-center !text-3xl"/>
                    <Heading1 :text="'home.welcome2'" class="!mb-3 font-dynapuff text-center !text-xl"/>

                    <div class="flex flex-col justify-center items-center text-center gap-1">
                        <Heading2 :text="'home.h1'" class="font-dynapuff"/>
                        <Text :text="'home.t1'"/>
                    </div>
                    <div class="flex flex-col justify-center items-center text-center gap-1">
                        <Heading2 :text="'home.h2'" class="font-dynapuff"/>
                        <Text :text="'home.t2.1'"/>
                        <Text :text="'home.t2.2'"/>

                    </div>
                    <div class="flex flex-col justify-center items-center text-center gap-1">
                        <Heading2 :text="'home.h3'" class="font-dynapuff"/>
                        <Text :text="'home.t3.1'"/>
                        <Text :text="'home.t3.2'"/>
                    </div>
                    <div class="flex flex-col justify-center items-center text-center gap-1">
                        <Heading2 :text="'home.h4'" class="font-dynapuff"/>
                        <Text :text="'home.t4'"/>
                    </div>
                    <div class="flex flex-col justify-center items-center text-center gap-1">
                        <Heading2 :text="'home.h5'" class="font-dynapuff"/>
                        <Text :text="'home.t5.1'"/>
                        <Text :text="'home.t5.2'"/>
                    </div>

                    <Heading1 :text="'home.welcome3'" class="!my-3 font-dynapuff text-center !text-xl"/>

                    <div class="flex gap-2 justify-center items-center">
                        <ButtonStd :active="true" @click="go('/login')" :label="'auth.login'"/> 
                        <ButtonStd :active="true" @click="go('/register')" :label="'auth.register'"/> 
                    </div>

                </template>
            </Tile>

        </div> 
        
    </Layout>
</template>